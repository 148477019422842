import React from 'react';
import styles from './NotificationMessage.module.scss';

export const sidebarWidth = (screenWidth: number) => {
  if (screenWidth >= 1660) {
    return 363;
  } else if (screenWidth >= 769 && screenWidth <= 1659) {
    return 308;
  } else {
    return 353;
  }
};

export const RawHTMLToString = (htmlText: string) => {
  const rawHTML = htmlText;
  const div = document.createElement('div');
  div.innerHTML = rawHTML;

  return div.textContent ?? div.innerText;
};

export const HandleMessagesEmpty = () => {
  return <div className={styles['no-notice']}>No messages yet.</div>;
};
