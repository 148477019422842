import React from 'react';
import styles from './AchievementModal.module.scss';
import AchievementSectionRedesign, {
  IAchievementSectionRedesign
} from '@src/Components/Organisms/AchievementSectionRedesign/AchievementSectionRedesign';

interface IAchievementModal extends IAchievementSectionRedesign {
  show: boolean;
  onClose: () => void;
  AccountId: string;
}

const AchievementModal: React.FC<IAchievementModal> = ({
  show,
  onClose,
  AccountId,
  ...props
}) => {
  return (
    <>
      {show && (
        <div className={styles['container']} data-testid="notification">
          <div className={styles['dropshadow']} />
          <div className={styles['wrapper']}>
            <div className={styles['header']}>
              <p className={styles['header-text']}>Achievements</p>
              <div
                className={styles['close-icon']}
                onClick={onClose}
                onKeyDown={() => {
                  return;
                }}
              >
                &#x2715;
              </div>
            </div>
            <AchievementSectionRedesign
              {...props}
              accountId={AccountId}
              isProfile={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AchievementModal;
