import React, { useState, useEffect } from 'react';
import styles from './FabGroupButton.module.scss';
import ButtonNavigation from '@src/Components/Atoms/Button/ButtonNavigation/ButtonNavigation';
import { motion } from 'framer-motion';

type IFabGroupButton = {
  isLocked?: boolean;
  onAchievementClick?: () => void;
  onNotificationClick?: () => void;
  onMessageClick?: () => void;
  isAchievementAlert?: boolean;
  isNotificationAlert?: boolean;
  isMessageAlert?: boolean;
};

const FabGroupButton: React.FC<IFabGroupButton> = ({
  isLocked,
  onAchievementClick,
  onNotificationClick,
  onMessageClick,
  isAchievementAlert,
  isNotificationAlert,
  isMessageAlert
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleIcon = () => {
    if (!isLocked) {
      if (isOpen) {
        return 'close.svg';
      } else if (!isOpen) {
        return 'menu.svg';
      }
    }

    return 'lock.svg';
  };

  useEffect(() => {
    if (isLocked) {
      setIsOpen(false);
    } else if (!isLocked) {
      setIsOpen(true);
    }
  }, [isLocked]);
  return (
    <div className={`${styles['container']} ${isOpen && styles['open']}`}>
      <motion.div
        initial={{ height: 0, display: 'none', overflow: 'hidden' }}
        animate={{
          height: isOpen ? 'auto' : 0,
          display: 'flex',
          padding: isOpen ? '8px 2px' : '0px 0px'
        }}
        transition={{ duration: 0.3 }}
        className={`${styles['button-childs-wrapper']} ${
          isOpen && styles['open']
        }`}
      >
        <div className={styles['button']}>
          <ButtonNavigation type="achievement" onClick={onAchievementClick} />
          {isAchievementAlert ? (
            <div className={styles['alert']} data-testid="alert" />
          ) : null}
        </div>
        <div className={styles['button']}>
          <ButtonNavigation type="notification" onClick={onNotificationClick} />
          {isNotificationAlert ? (
            <div className={styles['alert']} data-testid="alert" />
          ) : null}
        </div>
        <div className={styles['button']}>
          <ButtonNavigation type="mail" onClick={onMessageClick} />
          {isMessageAlert ? (
            <div className={styles['alert']} data-testid="alert" />
          ) : null}
        </div>
      </motion.div>
      <div className={styles['main-button-wrapper']}>
        <div
          className={`${styles['main-button']} ${isLocked && styles['locked']}`}
          onClick={() => {
            !isLocked && setIsOpen((prev) => !prev);
          }}
        >
          <img
            src={`assets/img/svg/button-navigation/${handleIcon()}`}
            alt="icon"
          />
        </div>
        {!isOpen &&
        (isMessageAlert || isAchievementAlert || isNotificationAlert) ? (
          <div className={styles['alert']} data-testid="alert" />
        ) : null}
      </div>
    </div>
  );
};

export default FabGroupButton;
