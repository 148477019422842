import { ListTitle } from '@jupiter/react-common-component';
import Select from '@src/Components/Atoms/Select/Select';
import React, { useState } from 'react';
import styles from './AchievementSectionRedesign.module.scss';
import { IGetAchievement } from './AchievementSectionRedesign.type';

type IAchievementLogo = {
  src?: string;
  isDisabled?: boolean;
};

const AchievementLogo: React.FC<IAchievementLogo> = ({ src, isDisabled }) => {
  return (
    <div className={styles['logo-wrapper']}>
      <img
        src={src}
        alt="logo"
        className={`${styles['logo']} ${isDisabled && styles['disabled']}`}
      />
    </div>
  );
};

export interface IAchievementSectionRedesign {
  accountId?: string;
  isProfile?: boolean;
  data?: IGetAchievement;
  loading?: boolean;
  totalAchievement?: number;
}

const AchievementSectionRedesign: React.FC<IAchievementSectionRedesign> = ({
  isProfile,
  data,
  loading,
  totalAchievement
}) => {
  const [filter, setFilter] = useState('all');

  const renderContent = () => {
    if (data !== undefined) {
      return (
        <div className={styles['container-list-title']}>
          {data?.data?.map((item: any) => (
            <div
              key={item?.achievement_id}
              className={`${styles['list-title']} `}
            >
              <ListTitle
                color="light"
                title={
                  <div
                    className={`${styles['title']} ${
                      !item.isOwned && styles['disabled']
                    }`}
                  >
                    {item?.title} &nbsp;
                    {item?.tags?.map((tag: any) => (
                      <span
                        key={tag}
                        className={`${styles['tag']} ${
                          !item?.isOwned && styles['disabled']
                        }`}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                }
                desc={
                  <div className={styles['description']}>
                    {item.description}
                  </div>
                }
                prefix={
                  <AchievementLogo
                    src={item.img_url}
                    isDisabled={!item.isOwned}
                  />
                }
              />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className={styles['empty']}>You don’t have any achievement</div>
      );
    }
  };

  return (
    <div className={styles['container']}>
      <div
        className={`${styles['title-section']} ${
          isProfile && styles['profile-page']
        }`}
      >
        <div className={styles['name-section']}>
          Achievements ({totalAchievement})
        </div>
        <div className={styles['select']}>
          <Select
            variant="gray-border"
            name="filter"
            label=""
            options={[{ text: 'All', value: 'all' }]}
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles['wrapper-list-title']}>
        <div id="achievement-content" className={styles['list-title-group']}>
          {loading ? (
            <div
              style={{ color: 'white', fontSize: '16px' }}
              className={styles['loading-wrapper']}
            >
              <div className={styles['spinner']}></div>
            </div>
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </div>
  );
};

export default AchievementSectionRedesign;
