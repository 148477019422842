import React from 'react';
import styles from './ProfileProgressbar.module.scss';
import { formatNumberWithDots } from '@src/Common/Utils/helper';

type IProfileProgressBar = {
  currentLevelPoint?: number;
  maxLevelPoint?: number;
};

const ProfileProgressBar: React.FC<IProfileProgressBar> = ({
  currentLevelPoint = 0,
  maxLevelPoint = 0
}) => {
  return (
    <div className={styles['container']}>
      <div
        className={styles['blue-bar']}
        style={{ width: `${(currentLevelPoint / maxLevelPoint) * 100}%` }}
      />
      <p className={styles['text']}>
        {formatNumberWithDots(currentLevelPoint)} Clearance Points
      </p>
    </div>
  );
};

export default ProfileProgressBar;
