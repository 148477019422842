import React from 'react';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import ButtonLiveTiles from '@src/Components/Atoms/Button/ButtonLiveTiles/ButtonLiveTiles';
import { logEvent } from '@src/Domain/analytic';
import { AnalyticsEvent } from '@src/Domain/analytic/analytic.type';

import styles from './GameLiveTiles.module.scss';

const GameLiveTiles = () => {
  const { width } = useWindowDimensions();

  const steamURL = 'steam://store/2282790';
  const steamWebsiteURL =
    'https://store.steampowered.com/app/2282790/RIFTSTORM/';

  const openSteam = () => {
    window.location.href = steamURL;
    logEvent(AnalyticsEvent.LINK_VISITED, 'Steam Riftstorm Live Tiles Click');
    setTimeout(() => {
      window.open(steamWebsiteURL, '_blank');
    }, 1000);
  };

  const handleBg = () => {
    if (width >= 1280) {
      return 'riftstorm-desktop.png';
    } else if (width >= 768 && width < 1280) {
      return 'riftstorm-tablet.png';
    } else {
      return 'riftstorm-mobile.png';
    }
  };

  return (
    <div
      className={styles['container']}
      style={{
        backgroundImage: `url(./assets/img/png/${handleBg()})`
      }}
      data-testid="game-live-tiles"
    >
      <div className={styles['content-wrapper']}>
        <div className={styles['button-wrapper']}>
          <ButtonLiveTiles
            color="blue"
            type="discord"
            onClick={() =>
              window.open(`https://discord.com/invite/riftstorm`, '_blank')
            }
          />
        </div>

        <div className={styles['button-wrapper']}>
          <ButtonLiveTiles color="black" type="steam" onClick={openSteam} />
        </div>
      </div>
    </div>
  );
};

export default GameLiveTiles;
