import { RawHTMLToString } from '@src/Components/Molecules/Notification/Notification.utils';
import React from 'react';
import styles from './SidebarItemNotif.module.scss';
import { ArrowIcon } from './SidebarItemNotifIcon';
import NotificationIcon from '../Icons/Notification/NotificationIcon';
import MessageCloseIcon from '../Icons/MessageCloseIcon/MessageCloseIcon';
import MessageOpenIcon from '../Icons/MessageOpenIcon/MessageOpenIcon';

type ISidebarItemNotif = {
  isLoading?: boolean;
  text?: string;
  alert?: boolean;
  isUnread?: boolean;
  type?: 'item' | 'subsidebar';
  typeIcon?: 'notification' | 'mail' | 'mail-opened';
  numberOfItem?: number | string;
  onClick?: () => void;
  isActive?: boolean;
  style?: React.CSSProperties;
  title?: string;
  description?: string;
  withBtnDelete?: boolean;
  onDelete?: () => void;
};

const SidebarItemNotif: React.FC<ISidebarItemNotif> = ({
  text = 'Welcome to Mythic Protocol',
  alert = false,
  type = 'item',
  typeIcon = 'notification',
  numberOfItem = 3,
  isUnread,
  onClick,
  isActive,
  style,
  title,
  description,
  withBtnDelete,
  onDelete,
  isLoading
}) => {
  const isItemAlert = alert && type === 'item';
  const alertElement = isItemAlert ? (
    <div className={styles['alert']} data-testid="alert" />
  ) : null;

  const handleIcon = (typeIcon: string) => {
    if (typeIcon === 'notification') return <NotificationIcon />;

    if (typeIcon === 'mail') return <MessageCloseIcon />;

    return <MessageOpenIcon />;
  };

  if (isLoading) {
    return (
      <div className={styles['loading-wrapper']}>
        <div className={styles['loading-icon']}></div>
        <div className={styles['loading-content']}></div>
        <div className={styles['loading-arrow']}>
          <ArrowIcon />
        </div>
      </div>
    );
  }

  return (
    <div className={styles['big-wrapper']}>
      <div
        className={`${styles['wrapper']} ${
          type === 'subsidebar' && styles['subsidebar']
        } ${isActive && styles['active']}`}
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
        }}
        onKeyDown={() => {
          return;
        }}
        style={style}
      >
        <div className={styles['icon-wrapper']}>
          {handleIcon(typeIcon)}
          {alertElement}
        </div>
        <div
          className={`${styles['container-mail']} ${
            type === 'subsidebar' && styles['subsidebar']
          }`}
        >
          {type === 'subsidebar' && (
            <>
              {text} {type === 'subsidebar' && `(${numberOfItem})`}
            </>
          )}
          <div className={styles['title-bar']}>
            <span
              className={`${styles['title-message']} ${
                isUnread ? styles['title-message-unread'] : ''
              }`}
            >
              {title}
            </span>
            <span
              className={`${styles['description-message']} ${
                isActive ? styles['description-message-active'] : ''
              }`}
            >
              {RawHTMLToString(description ?? '')}
            </span>
          </div>
        </div>
        {type === 'item' && (
          <div className={styles['arrow-wrapper']}>
            <ArrowIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarItemNotif;
