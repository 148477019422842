import SidebarItemNotif from '@src/Components/Atoms/SidebarItemNotif/SidebarItemNotif';
import { ArrowIcon } from '@src/Components/Atoms/SidebarItemNotif/SidebarItemNotifIcon';
import { MessageEnum } from '@src/Domain/Message/Message.constant';
import { StoreContext, withStore } from '@src/Store/Store.context';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { observer } from 'mobx-react-lite';
import { HandleMessagesEmpty } from './Notification.utils';
import styles from './NotificationMessage.module.scss';
import TrashIcon from '@src/Components/Atoms/Icons/TrashIcon/TrashIcon';
import MessageOpenIcon from '@src/Components/Atoms/Icons/MessageOpenIcon/MessageOpenIcon';

export interface MailboxDataType {
  mailboxId: number;
  authorAccountId: string;
  authorName: any;
  title: string;
  message: string;
  attachment: any;
  readStatus: number;
  claimUrl: any;
  claimStatus: number;
  expiredStatus: boolean;
  expiredDate: any;
  id: number;
  createDate: Date | string;
  updateDate: Date | string;
}

type INotificationMessage = {
  data: MailboxDataType[] | undefined;
  modalTitle: string;
  show: boolean;
  onClose: () => void;
  unreadLength: number;
  readLength: number;
  markAsRead: (mailboxId: number) => void;
  handleDeleteMessage: (mailboxId: number) => void;
};

const NotificationMessage: React.FC<INotificationMessage> = observer(
  ({
    data,
    show,
    modalTitle,
    onClose,
    unreadLength,
    readLength,
    markAsRead,
    handleDeleteMessage
  }) => {
    const { MessageStore } = useContext(StoreContext);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<MailboxDataType | undefined>(
      undefined
    );
    const [sidebarActive, setSidebarActive] = useState<number | undefined>(
      undefined
    );

    const hasMessages = data ? data.length > 0 : false;

    const handleClickNotification = (el: any) => {
      setDetailData(el);
      setShowDetail(true);
      if (!el.readStatus) {
        markAsRead(el.mailboxId);
      }
      setSidebarActive(el.mailboxId);
    };

    useEffect(() => {
      if (MessageStore.messageMethod === MessageEnum.DELETE)
        setDetailData(undefined);
      setShowDetail(false);
    }, [MessageStore.messageMethod]);

    const HandleDetailMessageData = useCallback(() => {
      const messageListContent =
        unreadLength === 0 && readLength === 0
          ? 'There are no messages.'
          : `Choose any ${modalTitle}.`;

      if (detailData)
        return (
          <>
            <div className={styles['header-right-content']}>
              <div className={styles['container-title-detail']}>
                <span
                  className={styles['back-for-mobile']}
                  onClick={() => setShowDetail(false)}
                >
                  <ArrowIcon />
                </span>
                <span className={styles['header-title']}>
                  {detailData?.title}
                </span>
              </div>
              <span
                className={styles['delete-icon']}
                onClick={() => {
                  handleDeleteMessage(detailData?.mailboxId);
                  setShowDetail(false);
                  setDetailData(undefined);
                }}
              >
                <TrashIcon />
              </span>
            </div>
            <div className={styles['paragraph-right-content']}>
              <div className={styles['display-date']}>
                {moment(detailData?.createDate).format('DD MMMM YYYY')}
              </div>
              <div className={styles['paragraph-desc']}>
                <div
                  className={styles['message-content']}
                  dangerouslySetInnerHTML={{
                    __html: detailData?.message || ''
                  }}
                />
              </div>
            </div>
          </>
        );

      return (
        <div className={styles['no-notice']}>
          <MessageOpenIcon />
          {messageListContent}
        </div>
      );
    }, [detailData, unreadLength, readLength, MessageStore.isLoading]);

    const contentShowMessage = () => {
      const openedDataMessage = data
        ?.toReversed()
        .filter((item: any) => item.readStatus === 1);
      const unreadDataMessage = data?.toSorted(
        (a, b) =>
          new Date(b.createDate).valueOf() - new Date(a.createDate).valueOf()
      );

      return (
        <div style={{ width: '100%', paddingTop: '14px' }}>
          <div className={styles['unread-wrapper']}>
            {unreadDataMessage?.map((el: MailboxDataType) => {
              if (el.readStatus === 0) {
                return (
                  <SidebarItemNotif
                    typeIcon={'mail'}
                    title={el.title}
                    description={el.message}
                    key={el.mailboxId}
                    alert={true}
                    onClick={() => handleClickNotification(el)}
                    isActive={sidebarActive === el.mailboxId}
                    isUnread={true}
                    withBtnDelete={true}
                    onDelete={() => handleDeleteMessage(el.mailboxId)}
                  />
                );
              }
            })}
          </div>
          <div className={styles['read-wrapper']}>
            {openedDataMessage?.map((el: MailboxDataType) => {
              if (el.readStatus === 1) {
                return (
                  <SidebarItemNotif
                    typeIcon={'mail-opened'}
                    title={el.title}
                    description={el.message}
                    key={el.mailboxId}
                    onClick={() => handleClickNotification(el)}
                    isActive={sidebarActive === el.mailboxId}
                    isUnread={false}
                    withBtnDelete={true}
                    onDelete={() => handleDeleteMessage(el.mailboxId)}
                  />
                );
              }
            })}
          </div>
        </div>
      );
    };

    const contentView = () => {
      if (MessageStore.isLoading === false)
        return (
          <div className={styles['content-wrapper']}>
            {hasMessages ? (
              <div
                className={`${styles['left-content']} ${
                  showDetail && styles['hide-in-mobile']
                }`}
              >
                {contentShowMessage()}
              </div>
            ) : null}
            <div
              className={`${styles['right-content']} ${
                showDetail && styles['show-in-mobile']
              } ${!hasMessages && styles['no-data']}`}
            >
              {hasMessages ? HandleDetailMessageData() : HandleMessagesEmpty()}
            </div>
          </div>
        );

      return (
        <div className={styles['loading-content-wrapper']}>
          <div
            className={`${styles['left-content-wrapper']} ${
              showDetail && styles['hide-in-mobile']
            }`}
          >
            <div className={`${styles['left-content']} `}>
              <SidebarItemNotif isLoading={true} />
            </div>
          </div>

          <div
            className={`${styles['right-content']} ${
              showDetail && styles['show-in-mobile']
            } ${!hasMessages && styles['no-data']}`}
          >
            <div className={styles['loading-indicator']}>
              <ReactLoading
                type={'spin'}
                color={'#878787'}
                height={32}
                width={32}
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        {show && (
          <div
            className={styles['container']}
            data-testid="notification-message"
          >
            <div className={styles['dropshadow']} />
            <div className={styles['wrapper']}>
              <div className={styles['header']}>
                <p className={styles['header-text']}>{modalTitle}</p>
                <div
                  className={styles['close-icon']}
                  onClick={onClose}
                  onKeyDown={() => {
                    return;
                  }}
                >
                  &#x2715;
                </div>
              </div>
              {contentView()}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default withStore(NotificationMessage);
