import React, { useContext } from 'react';
import styles from './ProfileCard.module.scss';
import { Button } from '@jupiter/react-common-component';
import ProfileProgressBar from '@src/Components/Atoms/ProfileProgressBar/ProfileProgressBar';
import SectionLevelClr from '@src/Components/Atoms/SectionLevelClr/SectionLevelClr';
import CardSectionWrapperSmall from '@src/Components/Molecules/CardSectionWrapper/CardSectionWrapperSmall/CardSectionWrapperSmall';
import { observer } from 'mobx-react-lite';
import { StoreContext, withStore } from '@src/Store/Store.context';

type IProfileCard = {
  handleSignIn: () => void;
  isAuthenticated?: boolean;
  profileImgUrl?: string;
  faction?: any;
  onFactionIconHover?: () => void;
  onFactionIconUnhover?: () => void;
  currentLevelPoint?: number;
  level?: number;
  requiredPointToNextLevel?: number;
  username?: string;
  tooltipRef: React.RefObject<HTMLDivElement>;
  maxLevelPoint: number;
  accountLevel?: number;
  onFactionIconClick?: () => void;
  handleAssignmentClick?: () => void;
};

const ProfileCard: React.FC<IProfileCard> = observer(
  ({
    handleSignIn,
    isAuthenticated = false,
    profileImgUrl = `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/img_default.png`,
    faction,
    onFactionIconHover,
    onFactionIconUnhover,
    currentLevelPoint,
    level,
    requiredPointToNextLevel,
    username,
    tooltipRef,
    maxLevelPoint,
    accountLevel = 0,
    onFactionIconClick,
    handleAssignmentClick
  }) => {
    const { HelperStore } = useContext(StoreContext);

    const factionIconClass = faction?.factionName
      ? `${faction.factionName}-icon`
      : 'faction-img';

    return (
      <div className={`${styles['container']}`}>
        <div
          className={`${styles['card']} ${
            !isAuthenticated && styles['not-signed-in']
          }`}
        >
          <div className={styles['inner']}>
            {!isAuthenticated && (
              <div className={styles['profile-not-login']}>
                <img
                  loading="lazy"
                  src={`./assets/img/png/default-portal.png`}
                  alt="profile-image-not-login"
                />
                <div className={styles['cta-to-login']}>
                  <div className={styles['cta-to-login-title']}>
                    <h1>Unknown User</h1>
                    <p>Login to verify credentials.</p>
                  </div>
                  <div className={styles['button-login-wrapper']}>
                    <Button
                      label="Login"
                      onClick={() => {
                        handleSignIn();
                        HelperStore.handleLoading('auth-loading', 2000);
                      }}
                      variant="default-magenta"
                      size="responsive"
                      isLoading={HelperStore.isLoading('auth-loading')}
                    />
                  </div>
                </div>
              </div>
            )}
            {isAuthenticated && (
              <div className={styles['content-wrapper']}>
                <div className={styles['profile-wrapper']}>
                  <div className={styles['profile-img']}>
                    <img
                      loading="lazy"
                      src={profileImgUrl}
                      alt="profile-image"
                    />
                    <div className={styles['level-badge']}>{accountLevel}</div>
                  </div>
                  <div className={styles['profile-description']}>
                    <div className={styles['name-wrapper']}>
                      <div className={styles['profile-name']}>{username}</div>
                      {faction?.imgUrl && faction && (
                        <div
                          className={styles['faction-image-wrapper']}
                          onMouseEnter={onFactionIconHover}
                          onMouseLeave={onFactionIconUnhover}
                          ref={tooltipRef}
                        >
                          <img
                            src={faction?.imgUrl}
                            alt={factionIconClass}
                            data-tooltip-id="factionTooltip"
                            onClick={onFactionIconClick}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles['clearance-bar']}>
                      <ProfileProgressBar
                        currentLevelPoint={currentLevelPoint}
                        maxLevelPoint={maxLevelPoint}
                      />
                    </div>
                    <div className={styles['level-wrapper']}>
                      <SectionLevelClr
                        level={level}
                        requiredPointToNextLevel={requiredPointToNextLevel}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`${styles['profile-link']}`}>
          <CardSectionWrapperSmall
            isLocked={false}
            type="assignment"
            title="ASSIGNMENT DASHBOARD"
            onClick={() => {
              handleAssignmentClick?.();
            }}
          />
          {/* <CardSectionWrapperSmall
          type="inventory-box"
          title="INVENTORY"
          onClick={() => {
            return;
          }}
        /> */}
        </div>
      </div>
    );
  }
);

export default withStore(ProfileCard);
