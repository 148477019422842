import { StoreContext, withStore } from '@src/Store/Store.context';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAuth } from 'react-oidc-context';

import { handleTargetBackgroundImg } from '@src/Common/Constants/handleTarget';
import { useEventCountry } from '@src/Common/Hooks/useEventCountry';
import { useHome } from '@src/Common/Hooks/useHome';
import { useHomeScroll } from '@src/Common/Hooks/useHomeScroll';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

import AchievementModal from '@src/Components/Atoms/Modal/AchievementModal/AchievementModal';
import Footer from '@src/Components/Layouts/Footer/Footer';
import NotificationMessage from '@src/Components/Molecules/Notification/NotficationMessage';
import NotificationRedesign from '@src/Components/Molecules/Notification/NotificationRedesign';

import { useAchievementsFetch } from '@src/Common/Hooks/useAchievements';
import useURLHash from '@src/Common/Hooks/useURLHash';
import BarKeystone from '@src/Components/Molecules/BarKeystone/BarKeystone';
import CardSectionWrapper from '@src/Components/Molecules/CardSectionWrapper/CardSectionWrapper';
import FabGroupButton from '@src/Components/Molecules/FabGroupButton/FabGroupButton';
import FooterDesktop from '@src/Components/Molecules/FooterDesktop/FooterDesktop';
import GameBannerSection from '@src/Components/Molecules/GameBannerSection/GameBannerSection';
import ModalAssignment from '@src/Components/Molecules/ModalAssignment/ModalAssignment';
import ModalGrantedInduction from '@src/Components/Molecules/ModalGrantedInduction/ModalGrantedInduction';
import ModalKeystone from '@src/Components/Molecules/ModalKeystone/ModalKeystone';
import PortalOpeningVideo from '@src/Components/Molecules/PortalOpeningVideo/PortalOpeningVideo';
import ProfileCard from '@src/Components/Organisms/ProfileCard/ProfileCard';
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import styles from './FullScreenGlobe.module.scss';
import {
  dummyPlaytestEnd,
  dummyPlaytestEndCountdown,
  dummyPlaytestOrder,
  dummyPlaytestStart,
  dummyPlaytestStartCountdown,
  dummyPlaytestStartCountdownActive
} from './FullScreenGlobe.utils';
import GlobeMythic from './Globe';
import { CountryType } from './Globe/Globe.type';

const FullScreenGlobe = observer(() => {
  const { GlobeStore, ThreatIndexStore, LevelStore } = useContext(StoreContext);
  const { width } = useWindowDimensions();
  const { isAuthenticated } = useAuth();
  const { accountLevel, userPointData } = LevelStore;

  const tileRef = useRef<HTMLDivElement>(null);
  const homepageRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const [indexCountry, setIndexCountry] = useState<number>(0);
  const [country, setCountry] = useState<CountryType[]>([]);
  const [faction, setFaction] = useState<any>(undefined);

  const { value: valueAchievementHash, removeHash: removeAchievementHash } =
    useURLHash('achievement');

  const [showModalAchievement, setShowModalAchievement] =
    useState<boolean>(false);

  const [_seasonData, setSeasonData] = useState<{
    is_active: boolean;
    season_start: string;
    season_end: string;
  } | null>(null);

  const [isScrolledToEdge, setIsScrolledToEdge] = useState(false);
  const [showModalAssignment, setShowModalAssignment] =
    useState<boolean>(false);
  const [showVideoLoading, setShowVideoLoading] = useState<boolean>(false);
  const [showModalKeystone, setShowModalKeystone] = useState<boolean>(false);

  const tooltipRef: React.RefObject<HTMLDivElement> = useRef(null);

  const {
    scrollToHomepageTop,
    //  isTileContentAtBot,
    isTileContentAtTop
  } = useHomeScroll({
    tileRef,
    homepageRef,
    profileRef
  });

  const {
    setShowNotification,
    showNotification,
    dataNotices,
    unreadLengthNotice,
    readLengthNotice,
    updateStatusNotice,
    getStatusNotice,
    markAsReadNotice,
    updateArrayRemovedIdNotice,
    ProfileStore,
    userToken,
    signInHandle,
    showModalMessages,
    setShowModalMessages,
    mailboxData,
    unreadMailbox,
    readMailbox,
    markAsReadMessage,
    handleDeleteMessage,
    accountId,
    showModalInductionAccess,
    setShowModalInductionAccess,
    username,
    totalKillQuard,
    totalNotKillQuard,
    totalKillQuardTimestamp,
    totalNotKillQuardTimestamp
  } = useHome();

  const { events, getListEventCountry } = useEventCountry({
    indexCountry,
    country
  });

  useEffect(() => {
    axios
      .get(`${process.env.MYTHIC_GLOBAL_EVENT_URL}/country`)
      .then((result) => {
        const countryWithEvent = result.data.data.filter(
          (item: CountryType) => item.totalEvent > 0
        );

        setCountry(countryWithEvent);
        GlobeStore.handleSelectCountry(countryWithEvent[0]);
        getListEventCountry(countryWithEvent[0]?.id);
      });
  }, []);

  //Add riftstorm event
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/json/riftstormMarker.json`
        )
        .then((res: any) => {
          const newData = res?.data;

          if (newData) {
            setCountry((prevCountry) => [
              ...prevCountry,
              ...newData.map((item: any) => ({
                id: item.id,
                markerPositionX: item.location.longitude,
                markerPositionY: item.location.latitude,
                name: item.dum_location_name,
                totalEvent: 1,
                type: item.type
              }))
            ]);
          }
        });
    }, 500);
  }, []);

  const getFactionName = async (token: string) => {
    try {
      const fetchFaction = await axios.get(
        `${process.env.MYTHIC_INDUCTION_HUB_V2_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const { data } = fetchFaction as any;
      setFaction(data.data);
    } catch (err) {
      return null;
    }
  };

  const getCurrentSeason = async () => {
    try {
      const response = await axios.get(
        `${process.env.LEADERBOARD_URL}/leaderboard/seasonal`
      );
      const { data } = response as any;
      const datas = data.data;
      setSeasonData((prev) => ({
        ...prev,
        is_active: datas.is_active,
        season_start: datas.season_start,
        season_end: datas.season_end
      }));
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (userToken) {
        getFactionName(userToken);
        LevelStore.getLevelData(userToken);
      }
    }
  }, [isAuthenticated, userToken]);

  useEffect(() => {
    getCurrentSeason();
    ThreatIndexStore.getThreatIndex();
  }, []);

  // useEffect(() => {
  //   if (isAuthenticated && faction) {
  //     setShowModalAssignment(false);
  //     if (valueAchievementHash === 'achievement') {
  //       setShowModalAchievement(true);
  //     }
  //   } else if (!isAuthenticated || !faction) {
  //     setShowModalAssignment(true);
  //   }
  // }, [isAuthenticated, faction]);

  const viewFooter = () => {
    if (width >= 1280) return <Footer />;
    if (width <= 1279)
      return (
        <div className={styles['footer-tablet']}>
          <Footer />
        </div>
      );

    return null;
  };

  useEffect(() => {
    const hasInductionAccess = localStorage.getItem('hasInductionAccess');

    if (hasInductionAccess) {
      setShowModalInductionAccess(false);
    } else if (!hasInductionAccess && faction) {
      setShowModalInductionAccess(true);
      localStorage.setItem('hasInductionAccess', 'true');
    }
  }, [faction]);

  const handleVideoLoading = useCallback(() => {
    if (isAuthenticated) {
      setShowVideoLoading(false);
    } else if (!isAuthenticated) {
      if (window.location.pathname !== '/data-deletion-instruction') {
        setShowVideoLoading(true);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    handleVideoLoading();
  }, [handleVideoLoading]);

  const {
    data,
    loading,
    totalAchievement,
    isNewAchievement,
    handleResetLocalAchievements
  } = useAchievementsFetch(accountId);

  const handleShowModalAssignment = (
    type: 'achievement' | 'announcement' | 'messages' | 'assignment',
    showModal: boolean
  ) => {
    if (!isAuthenticated || !faction) {
      setShowModalAssignment(true);
      return;
    }

    if (type === 'achievement') {
      if (accountId && !loading) {
        removeAchievementHash();
        setShowModalAchievement(showModal);
      }
    } else if (type === 'announcement') {
      setShowNotification(showModal);
    } else if (type === 'messages') {
      setShowModalMessages(showModal);
    } else if (type === 'assignment') {
      window.open(process.env.URL_XPSR_QUEST, '_blank');
    }
  };

  return (
    <div
      style={{
        backgroundImage: handleTargetBackgroundImg(width)
      }}
      ref={homepageRef}
      className={styles['container-full-screen']}
      data-testid="full-screen-globe"
      onScroll={(e) => {
        const element = e.currentTarget;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        // Check if scrolled to the bottom
        if (
          scrollTop + clientHeight >=
          parseFloat(scrollHeight.toFixed(1)) - 20 // [20] is the threshold
        ) {
          setIsScrolledToEdge(true);
        } else {
          setIsScrolledToEdge(false);
        }
      }}
    >
      {width >= 1280 && (
        <div
          style={{
            position: 'relative',
            zIndex: 10
          }}
        >
          <GlobeMythic
            listCountry={country}
            listEvent={events}
            setIndexCountry={setIndexCountry}
            scrollToHomepageTop={scrollToHomepageTop}
          />
        </div>
      )}

      <div className={styles['container-left-content']} ref={tileRef}>
        {isTileContentAtTop && (
          <div
            className={styles['content-top-gradient']}
            style={{
              background:
                'linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%)'
            }}
          />
        )}
        <section className={styles['flowting-left__content']} ref={profileRef}>
          <div className={styles['content-wrapper']}>
            <div className={styles['profile-card-wrapper']}>
              <ProfileCard
                username={username}
                handleSignIn={signInHandle}
                isAuthenticated={isAuthenticated}
                profileImgUrl={ProfileStore?.adminAccountData?.picture}
                faction={faction}
                level={userPointData?.level}
                requiredPointToNextLevel={
                  userPointData?.requiredPointToNextLevel
                }
                currentLevelPoint={userPointData?.currentLevelPoint}
                tooltipRef={tooltipRef}
                maxLevelPoint={
                  userPointData?.currentLevelPoint +
                  userPointData?.requiredPointToNextLevel
                }
                accountLevel={accountLevel}
                onFactionIconClick={() => {
                  window?.location?.assign(
                    `${process.env.MYTHIC_INDUCTION_HUB_V2}/result` || ''
                  );
                }}
                handleAssignmentClick={() =>
                  handleShowModalAssignment('assignment', true)
                }
              />
            </div>
            <div className={styles['section-title']}>PROTOCOL INTIATIVES</div>
            <div className={styles['group-card-1']}>
              <div className={styles['section-card']}>
                <GameBannerSection
                  isViewCountdown={false}
                  targetDate={
                    new Date(dummyPlaytestStartCountdownActive * 1000)
                  }
                  seasonStart={dummyPlaytestStartCountdown}
                  seasonEnd={dummyPlaytestEndCountdown}
                  isActive={
                    dayjs().unix() > dummyPlaytestStartCountdown.unix() &&
                    dayjs().unix() < dummyPlaytestEndCountdown.unix()
                  }
                  playTestOrder={dummyPlaytestOrder}
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="XPSR-24"
                  description="Track, surveil, and access operative-based assets."
                  type="xpsr"
                  isLocked={false}
                  onClick={() => {
                    window.open(process.env.URL_XPSR, '_blank');
                  }}
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="REPOSITORY ACCESS"
                  description="Official database of mythics, dossiers,
                  and casefiles."
                  type="repository"
                  isLocked={false}
                  onClick={() => {
                    window.open(process.env.MYTHIC_REPOSITORY_URL, '_blank');
                  }}
                />
              </div>
            </div>
            <div className={styles['section-title']}>operational review</div>
            <div className={styles['group-card-1']}>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="THE WARD"
                  description="C - 6652"
                  type="repository"
                  isLocked={false}
                  onClick={() => {
                    window.open(`${process.env.HOST_URL}/the-ward`, '_blank');
                  }}
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="OPERATION “DOGGY DAYCARE”"
                  description="C - 1787"
                  type="repository"
                  isLocked={false}
                  onClick={() => {
                    window.open(
                      `${process.env.HOST_URL}/doggy-daycare`,
                      '_blank'
                    );
                  }}
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="OPERATION TOMBSTONE"
                  description="C - 0268"
                  type="repository"
                  isLocked={false}
                  onClick={() => {
                    window.open(
                      `${process.env.HOST_URL}/op-tombstone`,
                      '_blank'
                    );
                  }}
                />
              </div>
            </div>
            <div className={styles['section-title']}>threat index</div>
            <div className={styles['group-card-1']}>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="threat index 2024"
                  description="Stay up to date with latest mythic threats."
                  type="repository"
                  isLocked={false}
                  onClick={() => {
                    window.open(process.env.URL_MYTHIC_ARG, '_blank');
                  }}
                />
              </div>
            </div>
            {width < 768 && <>{viewFooter()}</>}
            {width >= 1280 && <FooterDesktop />}
          </div>
        </section>
      </div>

      <div
        className={styles['back-to-top-arrow']}
        onClick={scrollToHomepageTop}
        onKeyDown={() => {
          return;
        }}
      >
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/icon-arrow-up-line.svg`}
          alt="arrow-up-icon"
        />
      </div>

      {width <= 1279 && (
        <div
          style={{
            position: 'relative',
            width: '100vw'
          }}
        >
          <GlobeMythic
            listCountry={country}
            listEvent={events}
            setIndexCountry={setIndexCountry}
            scrollToHomepageTop={scrollToHomepageTop}
          />
        </div>
      )}

      <div className={styles['floating-footer']}>
        <p className={styles['text1']}>Decentralized Universal Meta</p>
        <p className={styles['text2']}>BETA</p>
      </div>
      {width >= 768 && <>{viewFooter()}</>}

      <AchievementModal
        show={showModalAchievement}
        onClose={() => handleShowModalAssignment('achievement', false)}
        AccountId={accountId}
        data={data}
        loading={loading}
        totalAchievement={totalAchievement}
      />
      <NotificationRedesign
        typeIcon="notification"
        onClose={() => handleShowModalAssignment('announcement', false)}
        show={showNotification}
        modalTitle="Announcements"
        data={dataNotices}
        unreadLength={unreadLengthNotice || 0}
        readLength={readLengthNotice || 0}
        updateStatus={updateStatusNotice}
        getStatusNotice={getStatusNotice}
        markAsRead={markAsReadNotice}
        updateArrayRemovedIdNotification={updateArrayRemovedIdNotice}
      />
      <NotificationMessage
        show={showModalMessages}
        onClose={() => handleShowModalAssignment('messages', false)}
        data={mailboxData}
        modalTitle={'Messages'}
        unreadLength={unreadMailbox?.length || 0}
        readLength={readMailbox?.length || 0}
        markAsRead={markAsReadMessage}
        handleDeleteMessage={handleDeleteMessage}
      />
      <div
        className={`${styles['button-fab-wrapper']} ${
          isScrolledToEdge ? styles['button-fab-wrapper-in-the-edge'] : ''
        }`}
      >
        <FabGroupButton
          isAchievementAlert={isNewAchievement}
          isMessageAlert={unreadMailbox?.length > 0}
          isNotificationAlert={unreadLengthNotice > 0}
          isLocked={false}
          onAchievementClick={() => {
            handleResetLocalAchievements();
            handleShowModalAssignment('achievement', true);
          }}
          onNotificationClick={() =>
            handleShowModalAssignment('announcement', true)
          }
          onMessageClick={() => handleShowModalAssignment('messages', true)}
        />
      </div>

      <ModalAssignment
        handleClose={() => setShowModalAssignment(false)}
        show={showModalAssignment}
        numberStepActive={
          !isAuthenticated
            ? 1
            : isAuthenticated && !faction
            ? 2
            : isAuthenticated && faction
            ? 3
            : 0
        }
      />
      <ModalGrantedInduction
        show={showModalInductionAccess}
        onClose={() => setShowModalInductionAccess(false)}
      />
      <PortalOpeningVideo
        show={showVideoLoading}
        onEnded={() => {
          setShowVideoLoading(false);
        }}
      />
      <Tooltip
        id="factionTooltip"
        place={width < 1280 ? 'left-end' : 'right-end'}
        style={{
          color: '#E4E4E4',
          fontFamily: 'Barlow',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          background: '#0C0F12',
          textTransform: 'uppercase',
          zIndex: 9999
        }}
      >
        {faction?.factionName === 'Naga'
          ? 'Naga Society'
          : faction?.factionName}
      </Tooltip>
      <Tooltip
        id="my-tooltip-clr-level"
        place="bottom-end"
        role="tooltip"
        style={{
          color: '#E4E4E4',
          background: '#0C0F12',
          fontFamily: `Barlow`,
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: '400',
          width: '244px',
          zIndex: 9999
        }}
      >
        Complete assignments to earn Clearance Score to increase your Clearance
        Level.
      </Tooltip>
      <div
        className={`${styles[`bar-keystone-wrapper`]} ${
          isScrolledToEdge ? styles[`bar-keystone-wrapper-in-the-edge`] : ''
        }`}
      >
        {totalKillQuard > 0 || totalNotKillQuard > 0 ? (
          <BarKeystone
            onClick={() => setShowModalKeystone(true)}
            totalKillQuard={totalKillQuard}
            totalNotKillQuard={totalNotKillQuard}
          />
        ) : null}
      </div>
      {totalKillQuard > 0 || totalNotKillQuard > 0 ? (
        <ModalKeystone
          show={showModalKeystone}
          onClose={() => setShowModalKeystone(false)}
          totalKillQuard={totalKillQuard}
          totalNotKillQuard={totalNotKillQuard}
          state={
            !totalKillQuardTimestamp && !totalNotKillQuardTimestamp
              ? 'netral'
              : new Date(totalKillQuardTimestamp) >
                new Date(totalNotKillQuardTimestamp)
              ? 'kill-quard'
              : 'not-kill-quard'
          }
        />
      ) : null}
    </div>
  );
});

export default withStore(FullScreenGlobe);
