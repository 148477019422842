import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { StoreContext } from '@src/Store/Store.context';
import { useAuth } from 'react-oidc-context';

const DEFAULT_STATUS_LIST = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: ''
};

const DEFAULT_STATUS_CLAIM = {
  isLoading: undefined,
  isSuccess: false,
  isError: false,
  data: {
    day: 0,
    rewards: [],
    multiplier: 0
  },
  message: ''
};

const DEFAULT_LIST = {
  quests: [],
  totalRewards: [],
  multipliers: [],
  currentDay: 0,
  totalStreak: 0
};

const QUEST_PRODUCTID = process.env.QUESTS_DAILY_PRODUCTID;
const QUEST_API = process.env.QUESTS_SERVICE;

const useDailyCheckIn = () => {
  const { isAuthenticated } = useAuth();
  const { UserStore, LevelStore } = useContext(StoreContext);

  const [statusClaim, setStatusClaim] = useState(DEFAULT_STATUS_CLAIM);
  const [statusLists, setStatusLists] = useState(DEFAULT_STATUS_LIST);
  const [listDailyQuest, setListDailyQuest] = useState(DEFAULT_LIST);
  const [isAutoShow, setIsAutoShow] = useState(false);

  const hasFetched = useRef(false);

  const featchCheckIn = async (index: any) => {
    setStatusClaim({ ...DEFAULT_STATUS_CLAIM, isLoading: index });
    try {
      const reqBody = {
        productId: QUEST_PRODUCTID
      };

      await axios
        .post(`${QUEST_API}/check-in-quest`, reqBody, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${UserStore.token}`
          }
        })
        .then((res) => {
          const resCheckIn = res.data;
          setStatusClaim({
            ...DEFAULT_STATUS_CLAIM,
            isLoading: undefined,
            isSuccess: true,
            data: resCheckIn.data
          });
        })
        .catch(() => {
          throw new Error(
            'Something went wrong while claiming the reward. Please try again.'
          );
        });
    } catch (error: any) {
      setStatusClaim({
        ...DEFAULT_STATUS_CLAIM,
        isLoading: undefined,
        isError: true,
        message: error.message
      });
    }
  };

  const featchListDaily = async () => {
    setStatusLists({ ...DEFAULT_STATUS_LIST, isLoading: true });
    try {
      await axios
        .get(
          `${QUEST_API}/check-in-quests?ProductId=${QUEST_PRODUCTID}&Page=1&PageSize=1000`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${UserStore.token}`
            }
          }
        )
        .then((res) => {
          const dataListDaily = res?.data;
          setStatusLists({
            ...DEFAULT_STATUS_LIST,
            isLoading: false,
            isSuccess: true
          });
          setListDailyQuest(dataListDaily?.data);
          handleAutoShow(
            dataListDaily?.data?.quests,
            dataListDaily?.data?.currentDay
          );
        })
        .catch((error) => {
          throw new Error(
            error?.response?.data?.error?.message || error?.message
          );
        });
    } catch (error: any) {
      if (error?.message === 'Request failed with status code 401') {
        hasFetched.current = false;
      }

      setStatusLists({
        ...DEFAULT_STATUS_LIST,
        isLoading: false,
        isError: true,
        message: error.message
      });
    }
  };

  const handleCloseClaim = () => {
    setStatusClaim({ ...DEFAULT_STATUS_CLAIM });
  };

  const handleAutoShow = async (list: any, day: number) => {
    const isShow = list[day - 1]?.status === 'Unclaimed';
    setIsAutoShow(isShow);
  };

  const handleGetLevel = () => {
    LevelStore.getLevelData(UserStore.token ?? '');
  };

  useEffect(() => {
    if (statusClaim.isError) {
      setTimeout(() => {
        setStatusClaim({ ...DEFAULT_STATUS_CLAIM });
      }, 5000);
    }
  }, [statusClaim]);

  useEffect(() => {
    if (isAuthenticated && !UserStore.user?.expired && !hasFetched.current) {
      featchListDaily();
      hasFetched.current = true;
    }
  }, [!UserStore.user?.expired, hasFetched, statusLists]);

  return {
    isAutoShow,
    setIsAutoShow,
    listDailyQuest,
    statusLists,
    statusClaim,
    featchListDaily,
    featchCheckIn,
    handleCloseClaim,
    handleGetLevel
  };
};

export default useDailyCheckIn;
