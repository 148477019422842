import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './PortalOpeningVideo.module.scss';
import { VideoMedia } from '../Media';

type IPortalOpeningVideo = {
  show: boolean;
  onEnded?: () => void;
};

const PortalOpeningVideo: React.FC<IPortalOpeningVideo> = ({
  show,
  onEnded
}) => {
  const url = 'portal-opening-sequence-landscape';

  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <motion.div
          className={styles['container']}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
            filter: 'blur(10px)',
            transition: { duration: 1 }
          }}
        >
          <div className={styles['video-wrapper']}>
            <React.Suspense fallback={<></>}>
              <VideoMedia
                playbackRate={2}
                key={show ? 'video-on' : 'video-off'}
                isTag={false}
                autoPlay={true}
                url={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/video/${url}.mp4`}
                control={false}
                mute={true}
                onEnded={onEnded}
              />
            </React.Suspense>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PortalOpeningVideo;
