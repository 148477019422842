import React from 'react';

type INotificationIcon = React.SVGProps<SVGSVGElement>;

const NotificationIcon: React.FC<INotificationIcon> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M3.38994 7.2017C1.47694 7.71429 0.34178 9.68044 0.854366 11.5934C1.36695 13.5064 3.33311 14.6416 5.24611 14.129C5.39597 14.0887 5.5265 13.9962 5.6141 13.8681C5.70171 13.74 5.74064 13.5848 5.72384 13.4305L5.73381 13.4279L4.18922 7.66336C4.14442 7.49617 4.03505 7.35361 3.88517 7.26704C3.73528 7.18046 3.55715 7.15696 3.38994 7.2017ZM16.6082 0.630333C16.5633 0.463195 16.454 0.320705 16.3041 0.23418C16.1542 0.147655 15.9761 0.124176 15.809 0.168903C15.6645 0.207602 15.5466 0.293359 15.4623 0.40341L10.7738 5.092L5.77389 6.43173C5.60675 6.47658 5.46426 6.58596 5.37773 6.73583C5.29121 6.88569 5.26773 7.06378 5.31246 7.23095L6.91562 13.2141L6.92351 13.2119C6.98277 13.3591 7.09382 13.4796 7.23572 13.5506C7.37763 13.6217 7.54061 13.6384 7.69397 13.5976L12.1306 12.4088L19.1332 14.285C19.2736 14.3592 19.4406 14.3843 19.6061 14.34C19.7732 14.2951 19.9157 14.1857 20.0022 14.0359C20.0888 13.886 20.1122 13.7079 20.0675 13.5407C20.0611 13.5169 20.0506 13.4953 20.0419 13.4728L20.0488 13.471L16.618 0.666961L16.6143 0.667955C16.6117 0.655481 16.6116 0.643083 16.6082 0.630333ZM13.2579 17.2455L13.245 17.2326C13.2405 17.2278 13.2375 17.2224 13.2328 17.2177C13.228 17.213 13.2228 17.21 13.2181 17.2057L10.3994 14.3873L10.3934 14.3934C10.3243 14.3373 10.2443 14.296 10.1585 14.2721C10.0727 14.2482 9.98297 14.2423 9.89479 14.2547L9.89293 14.2477L8.21871 14.6963L8.21983 14.7005C8.12911 14.7323 8.04659 14.7838 7.97826 14.8515C7.85597 14.9739 7.78728 15.1398 7.78728 15.3129C7.78729 15.4859 7.856 15.6518 7.9783 15.7742C7.99997 15.7958 8.02402 15.8132 8.04789 15.8309L8.04165 15.8373L11.3266 19.1224L11.3273 19.1234L11.3283 19.1242L11.3612 19.1571L11.3645 19.1537C11.4886 19.2614 11.6489 19.3181 11.8132 19.3125C11.9774 19.3068 12.1334 19.2392 12.2498 19.1232C12.2782 19.0947 12.3024 19.0636 12.3245 19.0319L12.333 19.0403L13.3095 18.0641L13.3017 18.0562C13.3869 17.9361 13.4288 17.7907 13.4206 17.6437C13.4124 17.4966 13.3545 17.3568 13.2565 17.2469L13.2579 17.2455Z"
        fill="white"
      />
    </svg>
  );
};

export default NotificationIcon;
