import dayjs from 'dayjs';
import React from 'react';

import {
  dummyPlaytestEndCountdown,
  dummyPlaytestStartCountdown
} from '@src/Pages/FullScreenGlobe/FullScreenGlobe.utils';

import DiscordIcon from '../../Icons/DiscordIcon/DiscordIcon';
import { OpenInNewIcon } from '../../Icons/OpenInNewIcon/OpenInNewIcon';
import SteamIcon from '../../Icons/SteamIcon/SteamIcon';
import styles from './ButtonLiveTiles.module.scss';

interface IButtonLiveTiles {
  type: 'quest' | 'steam' | 'discord';
  color: 'pink' | 'black' | 'blue';
  onClick?: () => void;
}

const ButtonLiveTiles: React.FC<IButtonLiveTiles> = ({
  color,
  type,
  onClick
}) => {
  const handleButton = () => {
    if (type === 'quest') {
      return (
        <>
          <p className={styles['button-text']}>VIEW MISSIONS</p>
          <div className={styles['icon-wrapper']}>
            <OpenInNewIcon />
          </div>
        </>
      );
    } else if (type === 'steam') {
      return (
        <>
          <div className={styles['icon-wrapper']}>
            <SteamIcon />
          </div>
          <p className={styles['button-text']}>
            {dayjs().unix() > dummyPlaytestStartCountdown.unix() &&
            dayjs().unix() < dummyPlaytestEndCountdown.unix()
              ? 'PLAY NOW'
              : 'WISHLIST NOW'}
          </p>
        </>
      );
    } else if (type === 'discord') {
      return (
        <>
          <div className={styles['icon-wrapper']}>
            <DiscordIcon />
          </div>
          <p className={styles['button-text']}>JOIN NOW</p>
        </>
      );
    }
  };

  return (
    <button
      className={`${styles['button-container']} ${
        styles['button-container-' + color]
      }`}
      onClick={onClick}
    >
      <div className={styles['button-content']}>{handleButton()}</div>
    </button>
  );
};

export default ButtonLiveTiles;
