import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import Countdown from '../Countdown/Countdown';
import GameLiveTiles from '../GameLiveTiles/GameLiveTiles';
import styles from './GameBannerSection.module.scss';

type IGameBannerSection = {
  isViewCountdown?: boolean;
  targetDate?: Date;
  isActive?: boolean;
  seasonStart?: Dayjs;
  seasonEnd?: Dayjs;
  playTestOrder?: string;
};

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 7);

const GameBannerSection: React.FC<IGameBannerSection> = ({
  isViewCountdown = false,
  targetDate = currentDate,
  isActive = true,
  playTestOrder = '3rd',
  seasonStart = dayjs(),
  seasonEnd = dayjs()
}) => {
  const isActiveCountdown = isViewCountdown;

  const viewCountDown = () => {
    if (!!seasonStart && !!seasonEnd) {
      return (
        <div className={styles['top-content']}>
          <Countdown
            playTestOrder={playTestOrder}
            isActive={isActive}
            targetDate={targetDate}
            seasonStart={seasonStart}
            seasonEnd={seasonEnd}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles['container']} data-testid="game-banner-section">
      {isActiveCountdown && viewCountDown()}
      <div className={styles['bottom-content']}>
        <GameLiveTiles />
      </div>
    </div>
  );
};

export default GameBannerSection;
