// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EzuGcrfGWUi4qGhzo4jQ{position:sticky;top:0;z-index:500;width:100%;height:24px;background:var(--Neutral-Black-300, #0C0F12);display:flex;justify-content:center;align-items:center;gap:2px;font-family:var(--fonts-normal);font-size:14px;color:var(--text-white, #E4E4E4)}.EzuGcrfGWUi4qGhzo4jQ p{margin:0}.EzuGcrfGWUi4qGhzo4jQ a{color:var(--text-white, #E4E4E4);font-weight:600;text-underline-offset:2px}@media only screen and (max-width: 767px){.EzuGcrfGWUi4qGhzo4jQ{font-size:12px}}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/Alert/Alert.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,KAAA,CACA,WAAA,CAEA,UAAA,CACA,WAAA,CACA,4CAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,OAAA,CAEA,+BAAA,CACA,cAAA,CACA,gCAAA,CAEA,wBACI,QAAA,CAGJ,wBACI,gCAAA,CACA,eAAA,CACA,yBAAA,CAIR,0CACI,sBACI,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containers": `EzuGcrfGWUi4qGhzo4jQ`
};
export default ___CSS_LOADER_EXPORT___;
