import React from 'react';

type IClrnPointIcon = React.SVGProps<SVGSVGElement>;

const ClrnPointIcon: React.FC<IClrnPointIcon> = (props) => {
  return (
    <svg
      {...props}
      width="33"
      height="12"
      viewBox="0 0 33 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="12" fill="#BB9645" />
      <path
        d="M7.10719 1.576H7.75519V4.156L6.53119 2.92C5.16319 3.196 4.14319 4.396 4.14319 5.836C4.14319 7.48 5.47519 8.812 7.10719 8.812H7.75519V10.096H7.10719C4.76719 10.096 2.85919 8.188 2.85919 5.836C2.85919 3.484 4.76719 1.576 7.10719 1.576ZM10.4929 8.716H14.7169V10.012H9.20888V1.672H10.4929V8.716ZM21.4103 10.012H19.9463L18.5783 7.456C18.5423 7.456 18.5063 7.456 18.4703 7.456H17.1023V10.012H15.8183V1.672H18.4703C20.0663 1.672 21.3623 2.968 21.3623 4.564C21.3623 5.656 20.7503 6.616 19.8503 7.108L21.4103 10.012ZM17.1023 2.956V6.172H18.4703C19.3583 6.172 20.0783 5.452 20.0783 4.564C20.0783 3.676 19.3583 2.956 18.4703 2.956H17.1023ZM28.4289 1.672H29.7129V10.012H28.5969L24.1569 4.048V10.012H22.8729V1.672H23.9889L28.4289 7.624V1.672Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export default ClrnPointIcon;
