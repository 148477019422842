import { StoreContext } from '@src/Store/Store.context';
import { useState, useEffect, useContext } from 'react';

interface IuseWindowDimensions {
  markAsRead: (id: number) => void;
  updateStatus: () => void;
  updateArrayRemovedIdNotification: (id: number) => void;
  data: any[];
}

export const useWindowDimensions = ({
  markAsRead,
  updateStatus,
  updateArrayRemovedIdNotification,
  data
}: IuseWindowDimensions) => {
  const { MessageStore } = useContext(StoreContext);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<
    | {
        id: number;
        title: string;
        content?: string;
        createDate?: string;
        bannerUrl: string;
      }
    | undefined
  >(undefined);
  const [sidebarActive, setSidebarActive] = useState<number | undefined>(
    undefined
  );

  const handleItemNotif = (el: any) => {
    setDetailData(el);
    setShowDetail(true);
    if (!el.readStatus) {
      markAsRead(el.id);
    }
    setSidebarActive(el.id);
  };

  const handleDeleteNotice = (id: any) => {
    updateArrayRemovedIdNotification(id);
    setDetailData(undefined);
    setShowDetail(false);
  };

  useEffect(() => {
    updateStatus();
  }, [showDetail, detailData, data]);

  const idNoticeIsRead: any = JSON.parse(
    localStorage.getItem('readStatusNotice') ?? '[]'
  );
  const idNoticeRemoved: any = JSON.parse(
    localStorage.getItem('removedIdNotice') ?? '[]'
  );

  const unreadDataNotice = data
    ?.toSorted(
      (a, b) =>
        new Date(b.createDate).valueOf() - new Date(a.createDate).valueOf()
    )
    .filter(
      (item: any) =>
        !idNoticeIsRead.includes(item.id) && !idNoticeRemoved.includes(item.id)
    );

  const filterOpenedDataNotice = data?.filter(
    (item: any) =>
      idNoticeIsRead.includes(item.id) && !idNoticeRemoved.includes(item.id)
  );

  const orderedData = filterOpenedDataNotice.toSorted(
    (a, b) => idNoticeIsRead.indexOf(b.id) - idNoticeIsRead.indexOf(a.id)
  );

  const HandleEmptyData =
    data?.length > 0
      ? 'No updates or announcements available.'
      : 'Choose any announcement.';

  useEffect(() => {
    if (data.length > 0 && filterOpenedDataNotice.length === 0) {
      localStorage.removeItem('readStatusNotice');
    }
  }, [data]);

  return {
    MessageStore,
    showDetail,
    setShowDetail,
    setDetailData,
    detailData,
    sidebarActive,
    handleItemNotif,
    handleDeleteNotice,
    unreadDataNotice,
    filterOpenedDataNotice,
    HandleEmptyData,
    orderedData
  };
};
