import React, { useMemo } from 'react';
import ReactLoading from 'react-loading';

import TooltipCustome from './Contents/TooltipCustome';
import FrameModalDaily from './Contents/FrameModalDaily';
import ItemDaily from './Contents/ItemDaily';
import SuccessfulClaimed from './Contents/SuccessfulClaimed';
import PopUpClaimed from './Contents/PopUpClaimed';
import { PERIOD, UTC, TIME, modalDailyHidden } from './Utils/FormatDate';

import useDailyCheckIn from './useDailyCheckIn';
import styles from './MoalDailyCheckin.module.scss';

interface IModalDailyChecking {
  isShow: boolean;
  onClose: () => void;
}

const ModalDailyChecking: React.FC<IModalDailyChecking> = ({
  isShow,
  onClose
}) => {
  const {
    isAutoShow,
    setIsAutoShow,
    listDailyQuest,
    statusLists,
    statusClaim,
    featchListDaily,
    featchCheckIn,
    handleCloseClaim,
    handleGetLevel
  } = useDailyCheckIn();
  const { quests, totalRewards, multipliers, currentDay, totalStreak } =
    listDailyQuest;

  const handleShow = () => {
    if (statusLists.isError) return false;
    if (isAutoShow && !statusClaim.isSuccess) return true;
    if (isShow && !statusClaim.isSuccess) return true;
    return false;
  };

  const handleStreak = (newIndex: number) => {
    const maxValue =
      totalStreak > multipliers.length ? multipliers.length : totalStreak;

    if (newIndex === maxValue) {
      return true;
    } else {
      return false;
    }
  };

  const viewTotalRewerd = useMemo(() => {
    const rewardPoints = totalRewards.reduce(
      (acc: { [key: string]: number }, result: any) => {
        if (result.pointTicker) {
          acc[result.pointTicker] = result.point ?? 0;
        }
        return acc;
      },
      { EXP: 0, CS: 0, PP: 0 }
    );

    return (
      <>
        <span className={styles['PP']}>PP {rewardPoints.PP}</span>
        <span className={styles['CS']}>CS {rewardPoints.CS}</span>
        <span className={styles['EXP']}>EXP {rewardPoints.EXP}</span>
      </>
    );
  }, [totalRewards]);

  if (modalDailyHidden) return null;
  return (
    <>
      <FrameModalDaily
        isShow={handleShow()}
        onClose={() => {
          onClose();
          setIsAutoShow(false);
        }}
      >
        <>
          <section className={styles['title']}>
            <img src={`assets/icons/riftstorm.svg`} alt="riftstrom" />
            <h3>playtest daily check-in</h3>
          </section>
          <section className={styles['header']}>
            <div>
              <p>Check-in Period: {PERIOD}</p>
              <p>
                *Check-in time resets at {TIME} {UTC}
              </p>
            </div>
            <div>
              <p>
                Multiplier streak bonus
                <img
                  src={`assets/icons/error.svg`}
                  alt="error-icon"
                  data-tooltip-id="streak-days"
                  style={{ cursor: 'pointer' }}
                />
              </p>
              <TooltipCustome id="streak-days" place="right-start">
                <p className={styles['tooltip-streak']}>
                  Consistent daily check-ins grant bonus multipliers.
                  {multipliers.map((item, index) => (
                    <span key={Number(index)}>
                      Day {index + 1}
                      {index + 1 >= multipliers.length && '+'} : {item}X
                    </span>
                  ))}
                </p>
              </TooltipCustome>
              <ul className={`${styles['streak-box']}`}>
                {multipliers.map((item, index) => (
                  <li
                    key={Number(index)}
                    data-tooltip-id={`streak-info-${index}`}
                    className={`${
                      styles[handleStreak(index + 1) ? 'active' : '']
                    }`}
                  >
                    {item}
                    {handleStreak(index + 1) && (
                      <TooltipCustome id={`streak-info-${index}`} place="left">
                        <p>Day {totalStreak} streak bonus</p>
                      </TooltipCustome>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section className={styles['contents']}>
            {statusLists.isLoading ? (
              <ReactLoading
                data-testid="loading-spinner"
                className={styles['loading-center']}
                type="spin"
                color="#18B5BD"
                height="10%"
                width="10%"
              />
            ) : (
              quests.map((item: any, index) => (
                <ItemDaily
                  key={Number(index)}
                  isLoading={statusClaim.isLoading === index}
                  currentDay={currentDay}
                  day={item.day}
                  index={Number(index)}
                  rewards={item?.rewards}
                  status={item?.status}
                  tooltipId={`day-info-${index}`}
                  onClick={() =>
                    index + 1 === currentDay &&
                    item?.status === 'Unclaimed' &&
                    featchCheckIn(index)
                  }
                />
              ))
            )}

            {quests.map((item: any, index) => (
              <div key={Number(index)} className={styles['tooltip-cards']}>
                {item.status === 'Unclaimed' && (
                  <TooltipCustome id={`day-info-${index}`} place="bottom">
                    <p>
                      {index + 1 === currentDay ? 'Claim reward' : 'Rewards'}
                    </p>
                  </TooltipCustome>
                )}
              </div>
            ))}
          </section>
          <section className={styles['footer']}>
            <div>
              <p>Total reward claimed</p>
              <p>{viewTotalRewerd}</p>
            </div>
            <div>Check-in daily and collect all the rewards.</div>
          </section>
        </>
      </FrameModalDaily>

      <PopUpClaimed
        isShow={statusClaim.isError}
        message={statusClaim.message}
      />

      <SuccessfulClaimed
        isShow={statusClaim.isSuccess}
        streak={`${statusClaim.data.multiplier}`}
        rewards={statusClaim.data.rewards}
        onClose={() => {
          handleCloseClaim();
          featchListDaily();
          onClose();
          handleGetLevel();
          setIsAutoShow(false);
        }}
      />
    </>
  );
};

export default ModalDailyChecking;
