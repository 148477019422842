import { MailboxDataType } from '@src/Components/Molecules/Notification/NotficationMessage';
import { makeAutoObservable } from 'mobx';

export interface IMessageStore {
  isUpdatedMessage: boolean;
  messageMethod: string;
  isLoading: boolean;
  messages?: any[];
  setMessages(messages: any[]): void;
}

export class MessageStore implements IMessageStore {
  isUpdatedMessage = false;
  messageMethod = '';
  isLoading = true;
  messages?: MailboxDataType[] | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setMessages(messages: any[]) {
    this.messages = messages;
  }
}

export const MessageStoreDefault: IMessageStore = {
  isUpdatedMessage: false,
  messageMethod: '',
  isLoading: true,
  messages: [],
  setMessages: (messages: any[]) => {
    // console.log('setMessages not implemented');
  }
};
