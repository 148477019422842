import { useState, useEffect, useMemo } from 'react';
import useLazyFetch from '@src/Common/Hooks/useLazyFetch';
import {
  getLocalStorageData,
  saveLocalStorageData
} from '../Utils/localstorage';

interface AchievementData {
  data: any[];
}

interface Item {
  id: number;
  isOwned: boolean;
  [key: string]: any; // Allow additional data
}

interface Update {
  id: number;
  isOwned: boolean;
  [key: string]: any; // Allow additional data
}

function diffAchievement<T extends Item>(oldArr: T[], newArr: T[]): Update[] {
  const updates: Update[] = [];

  newArr.forEach((newItem) => {
    const oldItem = oldArr.find((item) => item.id === newItem.id);
    if (oldItem && oldItem?.isOwned !== newItem?.isOwned) {
      updates.push(newItem);
    }
  });

  return updates;
}

export const useAchievementsFetch = (accountId: string) => {
  const [isNewAchievement, setIsNewAchievement] = useState<boolean>(false);

  const [getAchievement, { data, loading }] = useLazyFetch<AchievementData>({
    url: `${process.env.API_ACHIEVEMENT ?? ''}/achievement/${accountId}`,
    idempotencyId: '/profile',
    onSuccess: (response) => {
      // Check if the achievements are not saved in local storage
      if (!getLocalStorageData(`achievements-${accountId}`)) {
        return saveLocalStorageData(
          `achievements-${accountId}`,
          response?.data
        );
      }

      // Check if there's a new achievement
      const ownedAchievements = diffAchievement(
        getLocalStorageData(`achievements-${accountId}`),
        response?.data
      );

      // If there's a new achievement, set isNewAchievement to true
      if (ownedAchievements?.length > 0) {
        setIsNewAchievement(true);
      }
    }
  });

  const totalAchievement = useMemo(() => {
    if ((data?.data ?? []).length > 0) {
      return (data?.data ?? []).filter((item: any) => item.isOwned).length;
    }
    return 0;
  }, [data]);

  const handleResetLocalAchievements = () => {
    if (isNewAchievement) {
      saveLocalStorageData(`achievements-${accountId}`, data?.data);
      setIsNewAchievement(false);
    }
  };

  useEffect(() => {
    if (accountId) {
      getAchievement();
    }
  }, [accountId]);

  return {
    data,
    loading,
    totalAchievement,
    isNewAchievement,
    handleResetLocalAchievements
  };
};
