import dayjs from 'dayjs';

const PLAYTEST_START = process.env.PLAYTEST_START ?? 0;
const PLAYTEST_END = process.env.PLAYTEST_END ?? 0;

export const dummyPlaytestStartCountdown = dayjs(Number(PLAYTEST_START) * 1000);
export const dummyPlaytestEndCountdown = dayjs(Number(PLAYTEST_END) * 1000);

export const dummyPlaytestStartCountdownActive =
  dayjs().unix() > dummyPlaytestStartCountdown.unix()
    ? dummyPlaytestEndCountdown.unix()
    : dummyPlaytestStartCountdown.unix();

export const dummyPlaytestStart = dayjs(
  dummyPlaytestStartCountdown
).toISOString();

export const dummyPlaytestEnd = dayjs(dummyPlaytestEndCountdown).toISOString();

export const dummyPlaytestOrder = '3';
