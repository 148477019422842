import React from 'react';

type IMessageOpenIcon = React.SVGProps<SVGSVGElement>;

const MessageOpenIcon: React.FC<IMessageOpenIcon> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_7433_26835"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7433_26835)">
        <path
          d="M12 1L21.05 6.4C21.35 6.58333 21.5833 6.83333 21.75 7.15C21.9167 7.46667 22 7.8 22 8.15V19C22 19.55 21.8043 20.021 21.413 20.413C21.021 20.8043 20.55 21 20 21H4C3.45 21 2.97933 20.8043 2.588 20.413C2.196 20.021 2 19.55 2 19V8.15C2 7.8 2.08333 7.46667 2.25 7.15C2.41667 6.83333 2.65 6.58333 2.95 6.4L12 1ZM12 12.65L19.8 8L12 3.35L4.2 8L12 12.65Z"
          fill="#E4E4E4"
        />
      </g>
    </svg>
  );
};

export default MessageOpenIcon;
