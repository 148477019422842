import TrashIcon from '@src/Components/Atoms/Icons/TrashIcon/TrashIcon';
import SidebarItemNotif from '@src/Components/Atoms/SidebarItemNotif/SidebarItemNotif';
import { ArrowIcon } from '@src/Components/Atoms/SidebarItemNotif/SidebarItemNotifIcon';
import { withStore } from '@src/Store/Store.context';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactLoading from 'react-loading';
import styles from './Notification.module.scss';
import { useWindowDimensions } from './useNotificationRedesign';
import { checkHtml } from '@src/Common/Constants/checkHtmlData';
import moment from 'moment';
import NotificationBulbIcon from '@src/Components/Atoms/Icons/Notification/NotificationBulbIcon';

type INotificationRedesign = {
  show: boolean;
  onClose: () => void;
  modalTitle?: string;
  typeIcon: 'notification' | 'mail';
  data: any[];
  unreadLength: number;
  readLength: number;
  updateStatus: () => void;
  getStatusNotice: (id: number, type: number) => boolean;
  markAsRead: (id: number) => void;
  updateArrayRemovedIdNotification: (id: number) => void;
};

const NotificationRedesign: React.FC<INotificationRedesign> = observer(
  ({
    show = false,
    onClose,
    modalTitle = 'Notice',
    data,
    updateStatus,
    getStatusNotice,
    markAsRead,
    updateArrayRemovedIdNotification
  }) => {
    const {
      MessageStore,
      showDetail,
      setShowDetail,
      setDetailData,
      detailData,
      sidebarActive,
      handleItemNotif,
      handleDeleteNotice,
      unreadDataNotice,
      HandleEmptyData,
      orderedData
    } = useWindowDimensions({
      markAsRead,
      updateStatus,
      updateArrayRemovedIdNotification,
      data
    });

    const hasNotice = unreadDataNotice.length > 0 || orderedData.length > 0;

    const ComponentDetailData = () => {
      if (detailData && hasNotice)
        return (
          <>
            <div className={styles['header-right-content']}>
              <div
                className={styles['header-title-wrapper']}
                onClick={() => setShowDetail(false)}
              >
                <div className={styles['arrow-wrapper']}>
                  <ArrowIcon />
                </div>
                <p className={styles['header-text']}>{detailData.title}</p>
              </div>
              <div
                className={styles['delete-icon-wrapper']}
                onClick={() => {
                  updateArrayRemovedIdNotification(detailData.id);
                  setDetailData(undefined);
                  setShowDetail(false);
                }}
              >
                <TrashIcon />
              </div>
            </div>

            <div className={styles['paragraph-right-content']}>
              <div className={styles['display-date']}>
                {moment(detailData?.createDate).format('DD MMMM YYYY')}
              </div>
              {detailData.bannerUrl && (
                <div className={styles['image-wrapper']}>
                  <img src={detailData.bannerUrl} alt="banner" />
                </div>
              )}
              <div
                className={styles['notice-content']}
                dangerouslySetInnerHTML={{
                  __html: checkHtml(detailData.content)
                }}
              />
            </div>
          </>
        );

      return (
        <div className={styles['no-notice']}>
          {hasNotice ? (
            <>
              <NotificationBulbIcon />
              Select an announcement
            </>
          ) : (
            'No updates or announcements available.'
          )}
        </div>
      );
    };

    const componentNotif = () => {
      return (
        <div style={{ width: '100%', paddingTop: '14px' }}>
          <div className={styles['unread-wrapper']}>
            {unreadDataNotice?.map(
              (el: { id: number; title: string; content: string }) => {
                if (getStatusNotice(el.id, 1)) {
                  return (
                    <SidebarItemNotif
                      typeIcon={'notification'}
                      title={el.title}
                      key={el.id}
                      description={el.content}
                      alert={true}
                      onClick={() => {
                        handleItemNotif(el);
                      }}
                      isUnread={true}
                      isActive={sidebarActive === el.id}
                      withBtnDelete={true}
                      onDelete={() => handleDeleteNotice(el.id)}
                    />
                  );
                }
              }
            )}
          </div>
          <div className={styles['read-wrapper']}>
            {orderedData?.map(
              (el: { id: number; title: string; content: string }) => {
                return (
                  <SidebarItemNotif
                    typeIcon={'notification'}
                    title={el.title}
                    key={el.id}
                    description={el.content}
                    onClick={() => handleItemNotif(el)}
                    isActive={sidebarActive === el.id}
                    withBtnDelete={true}
                    isUnread={false}
                    onDelete={() => handleDeleteNotice(el.id)}
                  />
                );
              }
            )}
          </div>
        </div>
      );
    };

    const componentView = () => {
      if (MessageStore.isLoading === false)
        return (
          <div className={styles['content-wrapper']}>
            {hasNotice ? (
              <div
                className={`${styles['left-content-wrapper']} ${
                  showDetail && styles['hide-in-mobile']
                }`}
              >
                <div className={`${styles['left-content']} `}>
                  {componentNotif()}
                </div>
              </div>
            ) : null}

            <div
              className={`${styles['right-content']} ${
                showDetail && styles['show-in-mobile']
              } ${!hasNotice && styles['no-data']}`}
            >
              <ComponentDetailData />
            </div>
          </div>
        );

      return (
        <div className={styles['loading-content-wrapper']}>
          <div
            className={`${styles['left-content-wrapper']} ${
              showDetail && styles['hide-in-mobile']
            }`}
          >
            <div className={`${styles['left-content']} `}>
              <SidebarItemNotif isLoading={true} />
            </div>
          </div>

          <div
            className={`${styles['right-content']} ${
              showDetail && styles['show-in-mobile']
            } ${!hasNotice && styles['no-data']}`}
          >
            <div className={styles['loading-indicator']}>
              <ReactLoading
                type={'spin'}
                color={'#878787'}
                height={32}
                width={32}
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        {show && (
          <div className={styles['container']} data-testid="notification">
            <div className={styles['dropshadow']} />
            <div className={styles['wrapper']}>
              <div className={styles['header']}>
                <p className={styles['header-text']}>{modalTitle}</p>
                <div className={styles['close-icon']} onClick={onClose}>
                  &#x2715;
                </div>
              </div>
              {componentView()}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default withStore(NotificationRedesign);
