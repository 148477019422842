import React from 'react';

type INotificationBulbIcon = React.SVGProps<SVGSVGElement>;

const NotificationBulbIcon: React.FC<INotificationBulbIcon> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.33333 21V15.7692H5C4.44444 15.7692 3.97222 15.5785 3.58333 15.1971C3.19444 14.8157 3 14.3526 3 13.8077V9.88462C3 9.33974 3.19444 8.8766 3.58333 8.49519C3.97222 8.11378 4.44444 7.92308 5 7.92308H11L17.6667 4V19.6923L11 15.7692H9.33333V21H7.33333ZM19 16.2269V7.46538C19.6 7.98846 20.0833 8.62596 20.45 9.37788C20.8167 10.1298 21 10.9526 21 11.8462C21 12.7397 20.8167 13.5625 20.45 14.3144C20.0833 15.0663 19.6 15.7038 19 16.2269Z"
        fill="#E4E4E4"
      />
    </svg>
  );
};

export default NotificationBulbIcon;
