import React from 'react';

type IDiscordIconProps = React.SVGProps<SVGSVGElement>;

const DiscordIcon: React.FC<IDiscordIconProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M11.6504 1.73101C10.7994 1.33432 9.87805 1.04639 8.9183 0.880034C8.90989 0.879765 8.90152 0.881344 8.89378 0.884661C8.88604 0.887977 8.87912 0.89295 8.87351 0.899229C8.75834 1.11037 8.62398 1.3855 8.5344 1.59665C7.51643 1.44309 6.48118 1.44309 5.46321 1.59665C5.37363 1.3791 5.23927 1.11037 5.1177 0.899229C5.1113 0.886433 5.0921 0.880034 5.07291 0.880034C4.11316 1.04639 3.1982 1.33432 2.34082 1.73101C2.33443 1.73101 2.32803 1.73741 2.32163 1.74381C0.581285 4.34793 0.101411 6.88166 0.338149 9.38981C0.338149 9.4026 0.344547 9.4154 0.357343 9.4218C1.50904 10.2664 2.61595 10.7782 3.71007 11.1174C3.72926 11.1238 3.74846 11.1174 3.75485 11.1046C4.01079 10.7526 4.24113 10.3815 4.43947 9.99125C4.45227 9.96566 4.43947 9.94006 4.41388 9.93366C4.04918 9.7929 3.70367 9.62654 3.36456 9.4346C3.33896 9.4218 3.33896 9.38341 3.35816 9.36421C3.42854 9.31303 3.49892 9.25544 3.5693 9.20426C3.5821 9.19146 3.60129 9.19146 3.61409 9.19786C5.81511 10.2024 8.18889 10.2024 10.3643 9.19786C10.3771 9.19146 10.3963 9.19146 10.4091 9.20426C10.4795 9.26184 10.5499 9.31303 10.6203 9.37061C10.6458 9.38981 10.6458 9.4282 10.6139 9.44099C10.2811 9.63934 9.92924 9.7993 9.56453 9.94006C9.53894 9.94646 9.53254 9.97845 9.53894 9.99765C9.74369 10.3879 9.97403 10.759 10.2236 11.111C10.2428 11.1174 10.262 11.1238 10.2811 11.1174C11.3817 10.7782 12.4886 10.2664 13.6403 9.4218C13.6531 9.4154 13.6595 9.4026 13.6595 9.38981C13.941 6.49136 13.1924 3.97682 11.676 1.74381C11.6696 1.73741 11.6632 1.73101 11.6504 1.73101ZM4.77219 7.86061C4.11316 7.86061 3.5629 7.25277 3.5629 6.50416C3.5629 5.75556 4.10036 5.14772 4.77219 5.14772C5.45041 5.14772 5.98787 5.76196 5.98147 6.50416C5.98147 7.25277 5.44401 7.86061 4.77219 7.86061ZM9.23182 7.86061C8.57279 7.86061 8.02254 7.25277 8.02254 6.50416C8.02254 5.75556 8.56 5.14772 9.23182 5.14772C9.91004 5.14772 10.4475 5.76196 10.4411 6.50416C10.4411 7.25277 9.91004 7.86061 9.23182 7.86061Z"
        fill="white"
      />
    </svg>
  );
};

export default DiscordIcon;
