import React, { useMemo } from 'react';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import styles from './Alert.module.scss';

const Alert: React.FC = () => {
  const URL = `https://one.confiction.com/portal`;
  const { width } = useWindowDimensions();

  const handleViewText = useMemo(() => {
    if (width < 768) {
      return (
        <>
          <strong>Security:</strong>
          <p>
            Make sure the URL is{' '}
            <a href={URL}>https://one.confiction.com/portal</a>
          </p>
        </>
      );
    } else {
      return (
        <>
          <strong>Security Alert:</strong>
          <p>
            Ensure you’re accessing{' '}
            <a href={URL}>https://one.confiction.com/portal</a> – double-check
            the URL for accuracy
          </p>
        </>
      );
    }
  }, [width]);

  return <div className={styles['containers']}>{handleViewText}</div>;
};

export default Alert;
