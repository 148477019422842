import React from 'react';

type IMessageCloseIcon = React.SVGProps<SVGSVGElement>;

const MessageCloseIcon: React.FC<IMessageCloseIcon> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <mask
        id="mask0_7433_26821"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="24"
      >
        <rect x="0.0996094" width="25.7991" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7433_26821)">
        <path
          d="M4.39993 20C3.8087 20 3.30257 19.8042 2.88154 19.4125C2.46051 19.0208 2.25 18.55 2.25 18V6C2.25 5.45 2.46051 4.97917 2.88154 4.5875C3.30257 4.19583 3.8087 4 4.39993 4H21.5993C22.1906 4 22.6967 4.19583 23.1177 4.5875C23.5387 4.97917 23.7493 5.45 23.7493 6V18C23.7493 18.55 23.5387 19.0208 23.1177 19.4125C22.6967 19.8042 22.1906 20 21.5993 20H4.39993ZM12.9996 13L21.5993 8V6L12.9996 11L4.39993 6V8L12.9996 13Z"
          fill="#F2F2F2"
        />
      </g>
    </svg>
  );
};

export default MessageCloseIcon;
